// 轮播图
export const swiperList = [
  {
    picImg: require("@/assets/images/swiper/blur-1851426_640.jpg"),
  },
  {
    picImg: require("@/assets/images/swiper/concert-768722_640.jpg"),
  },
  {
    picImg: require("@/assets/images/swiper/boy-984293_640.jpg"),
  },
  {
    picImg: require("@/assets/images/swiper/ipad-605439_640.jpg"),
  },
  {
    picImg: require("@/assets/images/swiper/microphone-1209816_640.jpg"),
  },
  {
    picImg: require("@/assets/images/swiper/music-notes-3221097_640.jpg"),
  },
  {
    picImg: require("@/assets/images/swiper/piano-1655558_640.jpg"),
  },
  {
    picImg: require("@/assets/images/swiper/turntable-1337986_640.jpg"),
  },
];
