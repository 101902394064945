export const SONGSTYLE = [
  {
    name: "全部歌单",
    type: "One",
  },
  {
    name: "华语",
    type: "Two",
  },
  {
    name: "粤语",
    type: "Three",
  },
  {
    name: "欧美",
    type: "Four",
  },
  {
    name: "日韩",
    type: "Five",
  },
  {
    name: "轻音乐",
    type: "Six",
  },
  {
    name: "BGM",
    type: "Seven",
  },
  {
    name: "乐器",
    type: "Eight",
  },
];
